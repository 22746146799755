<template>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import Keycloak from 'keycloak-js';
import axios from 'axios'
export default {
    name: 'SSOKeyCloakLogin',
    components:{
        InfoAlert,
    },
    data() {
        return {
            authDetails:null,
            organisation_id:null,
            valid: false,
            info:null,
            showDismissibleAlert: false,
            loading: false,
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        validate(){
            this.getOrganisationId()
        },
        createLoginPayload(authenticated,parsedToken,token){
            let payload={}
            this.authDetails={}
            payload['keycloak_jwt']=token
            payload['authenticated']=authenticated
            payload['parsedToken']=parsedToken
            this.authDetails=payload
            this.validate()
        },
        init() {
            this.$store.dispatch('logOut')
            let keycloak = new Keycloak({
                url: 'https://auth.industryapps.net/auth',
                // url: 'https://auth.uat.industryapps.net/auth',
                realm: 'IndustryApps',
                clientId: 'enture-fe',
                // "credentials": {
                //     "secret": "3LW0bFYIlSq8Yr9EHZGpkxAZGva7zUrQ"
                // }  
                // credential: '3LW0bFYIlSq8Yr9EHZGpkxAZGva7zUrQ',
                // url: 'http://localhost:4000/',
                // realm: 'TestRealm',
                // clientId: 'TestClient'
            });
            keycloak.init({ onLoad: "login-required", checkLoginIframe: false}).then((auth) => {
                if (!auth) {
                    window.location.reload();
                } else {
                    this.createLoginPayload(auth,keycloak.tokenParsed,keycloak.token)
                }
            })
        },
        getOrganisationId(){
            axios.post(this.$store.state.api+'getOrganisationIdByUserEmail',{email_id:this.authDetails.parsedToken.email})
            .then(response=>{
                if(response.data.status==='success'){
                    this.loading=false
                    this.organisation_id=response.data.data.organisation_id
                }else{
                    this.info = response.data.msg
                    this.showDismissibleAlert = true
                    this.loading=false
                    this.$router.push("/")
                }
            }).catch(err=>{
                this.info=err
                this.loading=false
                this.showDismissibleAlert=true
                this.$router.push("/")
            })
        },
        loginUser(){
            let payload={
                userID:this.authDetails.parsedToken.email,
                organisation_id:this.organisation_id
            }
            axios.post(this.$store.state.api + "UAMloginThroughSSOKeycloak", payload,{headers: {Authorization: 'Bearer '+ this.authDetails.keycloak_jwt}})
            .then(response => {
                if (response.data.status == "success") {
                    this.$store.commit("setJWT", response.data.jwt)
                    this.$store.commit("setUser", response.data.user)
                    this.$store.commit("setLoggedIn", true)
                    localStorage.setItem('enture_token',response.data.jwt)
                    this.$store.dispatch("refresh")
                    this.$router.push("/dashboard")
                } else {
                    this.info = response.data.error
                    this.showDismissibleAlert = true
                    this.$router.push("/")
                }
                this.loading = false
            })
            .catch(error => {
                console.log(error)
                this.organisation_id= null
                this.info = error
                this.showDismissibleAlert = true
                this.loading = false
                this.$router.push("/")
            })
        }
    },
    watch:{
        organisation_id(){
            this.loginUser()
        }
    }
}
</script>